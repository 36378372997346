const Operatori = () => import("./Operatori")
const Operatore = () => import("./Operatore")
const FaseGql = () => import("./FaseGql")
const Fase = () => import("./Fase")
const Fasi = () => import("./Fasi")
const FasiGql = () => import("./FasiGql")
const Schedulazioni = () => import("./Schedulazioni")
const Schedulazione = () => import("./Schedulazione")

const ProgrammiPiega = () => import("./ProgrammiPiega")

const RiordinoLavorazioni = () => import("./RiordinoLavorazioni")

import store from "../../store"

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/av/operatori/:presente?/:reparto?/:attivo?',
        name: 'av-operatori',
        meta: {menu: 'avanzamenti', title: 'Lista Operatori'},
        component: Operatori,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/av/operatore/:matricola/:nome/:ordine?/:operazione?',
        name: 'av-operatore',
        meta: {menu: 'avanzamenti', title: 'Operatore'},
        component: Operatore,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/av/fasi/',
        name: 'av-fasi',
        meta: {menu: 'avanzamenti', title: 'Lista Fasi'},
        component: Fasi,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/av/fasi-gql/',
        name: 'av-fasi-gql',
        meta: {menu: 'avanzamenti', title: 'Lista Fasi'},
        component: FasiGql,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/av/fase/:fase/:ordine?/:operazione?',
        name: 'av-fase',
        meta: {menu: 'avanzamenti', title: 'Fase'},
        component: Fase,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/av/fase-gql/:fase/:ordine?/:operazione?',
        name: 'av-fase-gql',
        meta: {menu: 'avanzamenti', title: 'Fase'},
        component: FaseGql,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/av/schedulazioni/',
        name: 'av-schedulazioni',
        meta: {menu: 'avanzamenti', title: 'Lista Schedulazioni'},
        component: Schedulazioni,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/av/riordino/:carico?',
        name: 'av-riordinolavorazioni',
        meta: {menu: 'avanzamenti', title: 'Riordino Lavorazioni'},
        component: RiordinoLavorazioni,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/av/schedulazione/:fase',
        name: 'av-schedulazione',
        meta: {menu: 'avanzamenti', title: 'Schedulazione'},
        component: Schedulazione,
        beforeEnter: ifAuthenticated,
    },

    {
        path: '/av/programmipiega',
        name: 'av-progpiega',
        meta: {menu: 'avanzamenti', title: 'Programmi Piega'},
        component: ProgrammiPiega,
        beforeEnter: ifAuthenticated,
    },
]