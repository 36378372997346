<template>
    <v-container fluid>
        <div class="mb-5 display-1">
            <v-row dense align="center">
                <v-col>
                    Non Conformità
                </v-col>
                <v-col style="text-align: right">
                    <bt-si-no-null name="risolte" :status="filtro.risolte" :callback="fromButton" label="Risolte"/>
                </v-col>
            </v-row>
        </div>
        <m-loading :loading="loading"/>
        <v-data-iterator v-if="righe"
                         :items="filteredRighe"
                         :search.sync="search"
                         :items-per-page="50"
                         :footer-props="{
                'items-per-page-options': [50,100,500]
            }"
        >
            <template v-slot:header>
                <v-toolbar
                    class="mb-1"
                >
                    <v-row align="center">
                        <v-col>
                            <v-text-field
                                v-model="search"
                                clearable
                                flat
                                hide-details
                                prepend-inner-icon="mdi-magnify"
                                label="Filtra"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr class="caption text-uppercase">
                            <th scope="col">Numero</th>
                            <th scope="col">Riferimento</th>
                            <th scope="col">Data Ins</th>
                            <th scope="col">Articolo</th>
                            <th scope="col">Descrizione</th>
                            <th scope="col">Causa</th>
                            <th scope="col">Origine</th>
                            <th scope="col">Problema</th>
                            <th scope="col">Correzione</th>
                            <th scope="col">Azione Correttiva</th>
                            <th scope="col">Foto</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(riga,riga_id) in props.items"
                            v-bind:key="`${riga_id}-${riga.pk}-${riga.articolo}-${riga.numero}`"
                            :class="[`priorita-${riga.priorita}`,`color-${riga.risolta}`]">
                            <td>
                                {{ riga.numero }}
                            </td>
                            <td>
                                {{ riga.rif }}
                            </td>
                            <td>{{ riga.data_inserimento | moment('DD/MM/Y') }}</td>
                            
                            <td>
                                <bt-articolo :codice="riga.articolo"/>
                            </td>
                            <td>{{ riga.articolo_descrizione }}</td>
                            <td>
                                {{ riga.nc_causa }}
                            </td>
                            <td>
                                {{ riga.nc_origine }}
                            </td>
                            <td>
                                {{ riga.problema }}
                            </td>
                            <td>
                                {{ riga.correzione }}
                            </td>
                            <td>
                                {{ riga.soluzione }}
                            </td>
                            <td class="pa-0" style="width: 150px">
                                <v-lazy>
                                    <files-n-c :numero="riga.numero" :riga="riga.riga"/>
                                </v-lazy>
                            </td>
                            <td>
                                <v-lazy>
                                    <NcEdit :input="riga" :articolo="riga.articolo" :getdata="getData"/>
                                </v-lazy>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        
        </v-data-iterator>
    </v-container>
</template>

<script>
    
    import _ from 'lodash';
    import NcEdit from "./include/NcEdit";
    import BtArticolo from "../include/BtArticolo";
    import MLoading from "../include/MLoading";
    
    
    import {teamLeaderMixin} from "../../mixins/teamleader";
    import BtSiNoNull from "../include/BtSiNoNull.vue";
    import FilesNC from "../articoli/include/FilesNc.vue";
    
    export default {
        name: "RiepilogoNc",
        mixins: [teamLeaderMixin],
        components: {FilesNC, BtSiNoNull, NcEdit, BtArticolo, MLoading},
        data: () => ({
            wantsTeamLeader: true,
            otherGroups: ['Amministrazione', 'Qualità', 'Officina'],
            timer: '',
            search: '',
            righe: undefined,
            total_items: undefined,
            loading: true,
            showEdit: false,
            riga: undefined,
            filtro: {
                risolte: undefined,
            },
        }),
        beforeDestroy() {
            clearInterval(this.timer)
        },
        mounted() {
            this.timer = setInterval(this.refreshData, 300000);
            document.title = `Work - Nc da aggiornare`
            this.$vlf.getItem(`filtro_nc_riepilogo`).then(filtro => {
                if (filtro) this.filtro = filtro
                return this.$vlf.getItem(`nc_riepilogo`).then(righe => {
                    if (righe) this.righe = righe
                })
            }).finally(() => this.getData())
            
        },
        watch: {
            socketMessage(newVal) {
                if (newVal.route === 'nc') {
                    this.getData(true)
                }
            },
        },
        computed: {
            socketMessage() {
                return this.$store.state.socketmessage
            },
            filteredRighe: function () {
                let items = {}
                if (this.filtro.risolte === 'si') {
                    items = _.filter(this.righe, o => {
                        return o.risolta === 2
                    })
                } else if (this.filtro.risolte === 'no') {
                    items = _.filter(this.righe, o => {
                        return o.risolta !== 2
                    })
                } else {
                    items = this.righe
                }
                return items
            }
        },
        methods: {
            refreshData: function () {
                if (document.hasFocus()) {
                    this.getData()
                }
            },
            fromButton(name, value) {
                this.$set(this.$data.filtro, name, value)
                this.$vlf.setItem(`filtro_nc_riepolog`, this.filtro)
            },
            getData: async function () {
                let app = this
                app.loading = true
                return this.$work.get(`/arts/nc/`,).then(function (response) {
                    if (response !== undefined) {
                        app.righe = response.data
                    }
                }).finally(() => {
                    app.loading = false
                    app.$vlf.setItem(`nc_riepilogo`, app.righe)
                })
            }
        }
    }
</script>

<style scoped>
::v-deep .efficente {
    background-color: rgba(0, 128, 0, 0.21);
}

::v-deep .inefficente {
    background-color: rgba(255, 0, 0, 0.21);
}

::v-deep th {
    height: auto !important;
}

::v-deep .color-2 {
    background-color: rgba(95, 234, 108, 0.1);
}

::v-deep .color-2:nth-of-type(odd) {
    background-color: rgba(92, 215, 113, 0.13);
}

/*
::v-deep td {
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: gray;
}

::v-deep td:last-child {
    border-right-style: none;
}
*/

</style>