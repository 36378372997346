import store from "../../store"

const ControlloTempi = () => import("./ControlloTempi")
const CommesseInLavorazione = () => import("./CommesseInLavorazione")
const CommesseLavorate = () => import("./CommesseLavorate")
const ControlloOdp = () => import("./ControlloOdp")

const ProgrammiDaCompletare = () => import("./ProgrammiDaCompletare")

const HistoryDip = () => import("./HistoryDip")
const StatoDips = () => import("./StatoDips")

const ifAuthenticated = async (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
    } else if (to.query.token) {
        let res = await window.work_api.tokenlogin(to.query.token)
        if (res) {
            delete (to.query.token)
            next(to)
        } else {
            next('/login')
        }
    } else {
        next('/login')
    }
}

export default [
    {
        path: '/tempi/controllo/',
        name: 'tempi-controllo',
        meta: {menu: 'tempi', title: 'Controllo Tempi'},
        component: ControlloTempi,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/inlavorazione/',
        name: 'tempi-inlavorazione',
        meta: {menu: 'tempi', title: 'Commesse In Lavorazione'},
        component: CommesseInLavorazione,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/lavorate/',
        name: 'tempi-lavorate',
        meta: {menu: 'tempi', title: 'Commesse Lavorate'},
        component: CommesseLavorate,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/controllodp/',
        name: 'tempi-controllodp',
        meta: {menu: 'tempi', title: 'Controllo ODP'},
        component: ControlloOdp,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/programmidacompletare/',
        name: 'tempi-programmidacompletare',
        meta: {menu: 'tempi', title: 'Programmi da completare'},
        component: ProgrammiDaCompletare,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/statodips/:matricola',
        name: 'HistoryDip',
        meta: {menu: 'tempi', title: 'Stato Dipendente'},
        component: HistoryDip,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/statodips/',
        name: 'StatoDips',
        meta: {menu: 'tempi', title: 'Stato Dipendenti'},
        component: StatoDips,
        beforeEnter: ifAuthenticated
    },
]