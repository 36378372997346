export default {
    title: 'Acquisti',
    icon: 'mdi-cash-multiple',
    groups: ['Amministrazione', 'Acquisti'],
    submenu: [
        {
            title: 'Lamiera',
            route: {name: 'acquisti-lamiera'},
        },
        {
            title: 'Conto Lavoro',
            route: {name: 'acquisti-contolavoro'},
        },
        {
            title: 'Ricevimenti',
            route: {name: 'acquisti-ricevimenti'},
        },
    ]
}