const MagazzinoFornitore = () => import('./MagazzinoFornitore')
const RiepilogoFornitore = () => import('./RiepilogoFornitore')

import store from "../../store"

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/fornitore/magazzino',
        name: 'fornitore-magazzino',
        meta: {menu: 'fornitore', title: 'Magazzino Fornitore'},
        component: MagazzinoFornitore,
        beforeEnter: ifAuthenticated,

    },
    {
        path: '/fornitore/riepilogo',
        name: 'fornitore-riepilogo',
        meta: {menu: 'fornitore', title: 'Riepilogo Fornitore'},
        component: RiepilogoFornitore,
        beforeEnter: ifAuthenticated,

    },
]