 export default {
    title: 'Stampe',
    icon: 'mdi-printer',
    groups: ['Amministrazione','Magazzino','Tecnici','Tempi&Metodi','Qualita'],
    submenu: [
        {
            title: 'Spooler di Stampa',
            route: {name: 'spooler-stato'},
        },

    ]
}