import store from "../../store"


const ControlloPrezzi = () => import("./ControlloPrezzi")
const ControlloPrezziCliente = () => import("./ControlloPrezziCliente")
const ControlloOdv = () => import("./ControlloOdv")
const FixStorico = () => import("./FixStorico")
const BudgetPrevisionale = () => import("./BudgetPrevisionale")
const Bot = () => import("./Bot")
const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/staff/prezzi/:mese?',
        name: 'staff-prezzi',
        meta: {menu: 'staff', title: 'Controllo Prezzi'},
        component: ControlloPrezzi,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/staff/prezzicliente/:cliente/:indirizzo/:validate/:mese?',
        name: 'staff-prezzicliente',
        meta: {menu: 'staff', title: 'Controllo Prezzi Cliente'},
        component: ControlloPrezziCliente,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/staff/controllodv/',
        name: 'staff-controllodv',
        meta: {menu: 'staff', title: 'Controllo Ordini di Vendita'},
        component: ControlloOdv,
        beforeEnter: ifAuthenticated,
    }, {
        path: '/staff/budgetprevisionale/',
        name: 'staff-budgetprevisionale',
        meta: {menu: 'staff', title: 'Budget Previsionale'},
        component: BudgetPrevisionale,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/staff/fixstorico/',
        name: 'staff-fixstorico',
        meta: {menu: 'staff', title: 'Fix Storico'},
        component: FixStorico,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/staff/console/',
        name: 'staff-console',
        meta: {menu: 'staff', title: 'Console'},
        component: Bot,
        beforeEnter: ifAuthenticated,
    },
]