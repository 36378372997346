export default {
    title: 'Magazzino',
    icon: 'mdi-database',
    divider: true,
    groups: ['Laser', 'Admin', 'Amministrazione', 'Tecnici', 'Officina', 'Magazzino', 'Schedulazione', 'Milkrun', 'Tempi&Metodi', 'Qualità'],
    submenu: [
        {title: 'Ubicazioni', route: {name: 'MagazzinoUbicazioni', params: {}}},
        {
            title: 'PickList Breton', route: {name: 'PickListBreton', params: {}},
            groups: ['Amministrazione', 'Schedulazione', 'Magazzino']
        },
        {title: 'Lamiere', route: {name: 'Lamiere', params: {}}},
        {title: 'Tubi', route: {name: 'Tubi', params: {}}},
        {title: 'Ricambi', route: {name: 'Ricambi', params: {}}},
        {
            title: 'Preparazione Ordini',
            route: {name: 'PreparazioneOrdini', params: {}},
            groups: ['Amministrazione', 'Schedulazione', 'Magazzino']
        },
        {title: 'Avanzi Saldatura', route: {name: 'Svansi', params: {}}},
        {
            title: 'Fornitori',
            route: {name: 'Fornitori', params: {}},
            groups: ['Amministrazione', 'Schedulazione', 'Magazzino']
        },

    ]
}