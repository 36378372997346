<template>
  <v-scroll-x-transition>
    <v-progress-circular class="mloading" width="2" size="40" indeterminate color="info" v-if="loading">
      <v-img transition="scale-transition" src="@/assets/logo.svg" width="25"></v-img>
    </v-progress-circular>
  </v-scroll-x-transition>
</template>

<script>
  export default {
    name: "MLoading",
    props: {
      loading: {type: Boolean, default: false}
    }
  }
</script>

<style>
.mloading {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 9999;
}

</style>