export default {
    title: 'Tempi',
    icon: 'mdi-timer-outline',
    groups: ['Schedulazione', 'Amministrazione', 'Tempi&Metodi', 'Officina'],
    submenu: [
        {
            title: 'Commesse in Lavorazione',
            route: {name: 'tempi-inlavorazione'},
            //badge: {url: '/staff/prezzi/'},
            groups: ['Schedulazione', 'Amministrazione', 'Tempi&Metodi', 'Officina'],
        },
        {
            title: 'Commesse Lavorate',
            route: {name: 'tempi-lavorate'},
            //badge: {url: '/staff/prezzi/'},
            groups: ['Schedulazione', 'Amministrazione', 'Tempi&Metodi', 'Officina'],
        },
        {
            title: 'Controllo Tempi',
            route: {name: 'tempi-controllo'},
            //badge: {url: '/staff/prezzi/'},
            groups: ['Schedulazione', 'Amministrazione', 'Tempi&Metodi'],
        },
        {
            title: 'Controllo Odp',
            route: {name: 'tempi-controllodp'},
            groups: ['Schedulazione', 'Amministrazione', 'Tempi&Metodi'],
        },
        {
            title: 'Programmi da Completare',
            route: {name: 'tempi-programmidacompletare'},
            groups: ['Schedulazione', 'Amministrazione', 'Tempi&Metodi'],
        },
        {
            title: 'Operatori', route: {name: 'StatoDips'},
            groups: ['Schedulazione', 'Amministrazione', 'Tempi&Metodi'],
        },
    ]
}