const DaNestare = () => import("./DaNestare")
const Programmi = () => import("./Programmi")
const InTaglio = () => import("./InTaglio")
const Sbancalatura = () => import("./Sbancalatura")
const DaUbicare = () => import("./DaUbicare")
export default [
    {
        path: '/laser/intaglio/',
        name: 'laser-intaglio',
        meta: {menu: 'laser', title: 'In taglio'},
        component: InTaglio,
        title: 'Intaglio',
    },
    {
        path: '/laser/danestare/',
        name: 'laser-danestare',
        meta: {menu: 'laser', title: 'Da Nestare'},
        component: DaNestare,
        title: 'Da Nestare',
    },
    {
        path: '/laser/programmi/',
        name: 'laser-programmi',
        meta: {menu: 'laser', title: 'Programmi'},
        component: Programmi,
        title: 'Programmi',
    }, {
        path: '/laser/sbancalatura/',
        name: 'laser-sbancalatura',
        meta: {menu: 'laser', title: 'Sbancalatura'},
        component: Sbancalatura,
        title: 'Sbancalatura',
    },
    {
        path: '/laser/daubicare/',
        name: 'laser-daubicare',
        meta: {menu: 'laser', title: 'Da Ubicare'},
        component: DaUbicare,
        title: 'Da Ubicare',
    },
]