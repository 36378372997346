import store from "./store"
import ReconnectingWebSocket from 'reconnecting-websocket';
// eslint-disable-next-line
let wsUrl = process.env.VUE_APP_WORK_WS + "/";
// eslint-disable-next-line
let env = process.env.VUE_APP_SENTRY_ENV;

class WorkSocket {
    constructor(channel = undefined) {
        this._socket = undefined
        this.connected = false
        this.channel = channel
        this.connect()
    }

    connect() {

        const ws_scheme = window.location.protocol === "https:" ? "wss" : "ws";
        const ws_path = ws_scheme + '://' + wsUrl;

        this._socket = new ReconnectingWebSocket(ws_path)
        this._socket.onopen = () => {
            console.log(`SOCKET CONNECTED to ${ws_path}`)
            this.connected = true
            store.dispatch('socketconnected', true)
        }
        this._socket.onerror = (error) => {
            console.log("SOCKET ERROR", error)
            this.connected = false
            store.dispatch('socketconnected', false).then(() => {
            })
        }
        this._socket.onclose = () => {
            console.log("SOCKET CLOSED")
            this.connected = false
            store.dispatch('socketconnected', false).then(() => {
            })
        }
        this._socket.onmessage = ({data}) => {
            if (env === 'develop') {
                console.log("SOCKET MESSAGE", data)
            }
            if (data) {
                let payload = JSON.parse(data)
                // store payload.channel_name if present
                if (payload.channel_name) {
                    store.dispatch('socketchannel', payload.channel_name).then(() => {
                    })
                }
                store.dispatch('socketmessage', payload).then(() => {
                })
            }
        }
    }
}

const socket = new WorkSocket(wsUrl)
export default socket