<template>
    <ais-instant-search :search-client="searchClient" index-name="work-search" style="width: 40%">
        <ais-index index-name="work-search">
            <ais-configure :hitsPerPage="20"/>
            <ais-autocomplete>
                <template v-slot:="{ indices, refine,currentRefinement }">
                    <v-combobox
                        :items="searchKeyword ? sortByHl(indices[0].hits) : []"
                        @update:search-input="startSearch(refine)"
                        :search-input.sync="searchKeyword"
                        :value="currentRefinement"
                        @keydown.enter.prevent="goToRoute(indices)"
                        v-model="searchModel"
                        no-filter
                        class="mx-4 mt-8"
                        clearable
                        hide-no-data
                        color="white"
                        return-object
                        label="Cerca Articoli, Ordini"
                        placeholder="Inizia a scrivere per cercare"
                        prepend-icon="mdi-database-search"
                        ref="searchbox"
                    >
                        <template v-slot:item="{ item }">
                            
                            <v-list-item-avatar tile
                                                :class="[item.codice?.toLowerCase() === searchKeyword?.toLowerCase() ? 'search-item-ok' : 'search-item-ko']"
                            
                            >
                                <bt-disegno :width="50" :articolo="item.articolo"
                                            :disegno="item.disegno"
                                            :pdfShow="false"
                                ></bt-disegno>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span v-html="highlight(item.codice,currentRefinement)"></span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span v-html="highlight(item.descrizione,currentRefinement)"></span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-row dense class="flex-column">
                                    <v-col class="mb-0 pb-0">{{ item.route }}</v-col>
                                </v-row>
                            </v-list-item-icon>
                        
                        </template>
                    
                    </v-combobox>
                </template>
            </ais-autocomplete>
        </ais-index>
    </ais-instant-search>
</template>
<script>
    
    import {defineComponent} from "vue";
    import {AisAutocomplete, AisConfigure, AisIndex, AisInstantSearch} from "vue-instantsearch";
    import {bus} from "@/main";
    import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
    import _ from "lodash";
    import BtDisegno from "./BtDisegno.vue";
    
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        // test ricerca https://bfritscher.github.io/typesense-dashboard/#/login
        server: {
            apiKey: "eRUqn6rGQpTSyHwnoP41h5p2ysVzO50Z", // Be sure to use an API key that only allows search operations
            nodes: [
                {
                    host: "ts.mecomsrl.com",
                    path: "", // Optional. Example: If you have your typesense mounted in localhost:8108/typesense, path should be equal to '/typesense'
                    port: window.location.protocol === "https:" ? "443" : "80",
                    protocol: window.location.protocol === "https:" ? "https" : "http",
                },
            ],
            cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
        },
        // The following parameters are directly passed to Typesense's search API endpoint.
        //  So you can pass any parameters supported by the search endpoint below.
        //  query_by is required.
        additionalSearchParameters: {
            query_by: "codice_tokenized,articolo,descrizione,articolo_cli_tokenized,articolo_cli,cliente_facet,ragione_sociale_facet,varie,nestings,disegno_content",
            sort_by: "_text_match(buckets: 10):desc,orderby:asc"
        },
        
    });
    const searchClient = typesenseInstantsearchAdapter.searchClient;
    
    export default defineComponent({
        name: 'SearchBox',
        components: {BtDisegno, AisInstantSearch, AisAutocomplete, AisIndex, AisConfigure},
        data() {
            return {
                searchClient,
                query: "",
                searchKeyword: null,
                searchModel: undefined,
            }
        },
        computed: {},
        created() {
            let app = this
            bus.$on('scanned-barcode', function (barcode) {
                app.searchKeyword = barcode
            })
        },
        methods: {
            sortByHl(hits) {
                let app = this
                let sorted = _.sortBy(hits, function (o) {
                    // conto il numero di lettere in searchKeyword che sono presenti in codice
                    let myrank = 0
                    try {
                        if (typeof app.searchKeyword === 'object') {
                            myrank = 0
                        } else if (o.codice.toLowerCase().includes(app.searchKeyword?.toLowerCase())) {
                            myrank = app.searchKeyword?.length
                        } else {
                            myrank = 0
                        }
                        if (o.tabella === 'ITMMASTER') {
                            myrank = myrank + 100
                        }
                        return myrank
                    } catch (e) {
                        console.log(e)
                    }
                })
                return sorted.reverse()
            },
            highlight: function (text, search) {
                if (!search) {
                    return text
                }
                _.each(search.split(" "), function (s) {
                    if (!s || s.length <= 1) {
                        return
                    }
                    text = text.replace(new RegExp(s, "gi"), function (str) {
                        return "<span class='highlight'>" + str + "</span>"
                    });
                })
                return text
            },
            goGoGo: function (indices) {
                let app = this
                setTimeout(function () {
                    app.$refs.searchbox.blur()
                    app.$router.push({name: indices[0].hits[0].route, params: {codice: indices[0].hits[0].codice}})
                    app.searchKeyword = null
                }, 200)
            },
            goToRoute: function (indices) {
                let app = this
                // attendo un po' i risultati della ricerca
                if ((app.searchKeyword && app.searchKeyword.length > 0) && (indices.length > 0) && (indices[0].hits.length > 0)) {
                    if (indices[0].hits[0].codice === app.searchKeyword?.toUpperCase()) {
                        app.goGoGo(indices)
                    } else if (indices[0].hits[0].codice_cli === app.searchKeyword?.toUpperCase()) {
                        app.goGoGo(indices)
                    } else if (indices[0].hits.length === 1) {
                        app.goGoGo(indices)
                    } else if (indices[0].hits.length > 1 && app.$refs.searchbox.value && app.$refs.searchbox.value.length > 0) {
                        // solo se selezionato una riga
                        setTimeout(function () {
                            app.$refs.searchbox.blur()
                            app.$router.push({name: "Search", params: {query: app.searchKeyword}})
                        }, 200)
                        return false
                    } else {
                        // apri la pagina di ricerca
                        setTimeout(function () {
                            app.$refs.searchbox.blur()
                            app.$router.push({name: "Search", params: {query: app.searchKeyword}})
                        }, 200)
                        return false
                    }
                } else {
                    console.log("Niente da cercare")
                    return false
                }
                
            }
            ,
            startSearch: _.debounce(function (refine) {
                if (this.searchKeyword && this.searchKeyword.length > 0) {
                    console.log("searching for", this.searchKeyword)
                    refine(this.searchKeyword);
                } else {
                    refine("");
                }
                
            }, 200),
        },
        watch:
            {
                searchModel(val) {
                    let app = this
                    // check if val is an object
                    if (val !== null && typeof val === 'object') {
                        console.info("search route push", val)
                        app.$router.push({name: val.route, params: {codice: val.codice}}).then(function () {
                            setTimeout(function () {
                                app.$data.searchModel = null
                                app.$data.searchKeyword = null
                                // app.$data.searchEntries = []
                            }, 50)
                        })
                        
                    }
                }
                ,
            }
    })
</script>
<style scoped>

::v-deep(div.v-list-item:has(>.search-item-ok)) {
    background-color: #4e974e !important;
}

::v-deep(div.v-list-item:has(>.search-item-ko)) {
    opacity: 0.8 !important;
}


.searchinput {
    position: absolute;
    width: 100%;
    margin: 0;
    left: 0;
    z-index: 10;
    padding: 0 15px;
}

.descpart {
    max-width: 220px;
}

.filter-content {
    width: 100vw;
    left: 0;
    z-index: 5;
    border-radius: 0 !important;
}

.filter-menu {
    top: 64px !important;
    left: 0 !important;
    max-width: 100vw !important;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid #AAA;
}

.header-menu {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

::v-deep .ais-CurrentRefinements {
    .ais-CurrentRefinements-list {
        height: 0;
        overflow: hidden;
    }
}

::v-deep .highlight {
    background-color: rgb(225, 255, 0);
    color: black;
}

::v-deep .ais-RefinementList {
    position: relative;
    padding-bottom: 50px;
    min-height: 230px;
    
    .ais-RefinementList-list {
        display: flex;
        flex-wrap: wrap;
        
        .ais-RefinementList-item {
            width: 33%;
            color: black;
            
            @media (max-width: 600px) {
                width: 100%;
            }
            
            .ais-RefinementList-labelText {
                white-space: unset;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            .ais-RefinementList-count {
                display: none;
            }
        }
    }
    
    .ais-RefinementList-searchBox {
        position: absolute;
        bottom: 0px;
        padding: 10px;
        width: 100%;
    }
}

::v-deep .v-autocomplete__content.v-menu__content {
    max-height: 90vh !important;
}


</style>

