import Vue from 'vue';
import Vuetify, {VBtn, VIcon, VSnackbar} from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'
import it from 'vuetify/es5/locale/it';
import en from 'vuetify/es5/locale/en';
import VuetifyToast from 'vuetify-toast-snackbar-ng';
import Ripple from 'vuetify/lib/directives/ripple';

Vue.use(Vuetify, {
    directives: {
        Ripple,
    },
    components: {
        VSnackbar,
        VBtn,
        VIcon
    }
});
Vue.use(VuetifyToast);
export default new Vuetify({
    lang: {
        locales: {it, en},
        current: 'it',
    }, icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        dark: true,
        themes: {
            light: {
                primary: '#128c90',
                secondary: '#795548',
                accent: '#ffeb3b',
                error: '#f44336',
                warning: '#ff9500',
                info: '#2196f3',
                success: '#4caf50',
                link: '#386ef3',
            },
            dark: {
                primary: '#128c90',
                secondary: '#795548',
                accent: '#ffeb3b',
                error: '#f44336',
                warning: '#ff9500',
                info: '#2196f3',
                success: '#4caf50',
                link: '#22d4f3',
            }
        }
    },
});
