/*
Teamleade Mixin
fa apparire una schermaa che blocca l'accesso se non è un teamleader
*/

import _ from "lodash";

export let teamLeaderMixin = {

    data() {
        return {
            rules: {
                pinok: value => {
                    return value.length === 5 || 'Immettre un pin valido'
                },
                pinok2: value => {
                    return value.match(/^\d+$/) != null || 'Immettre un pin valido'
                }
            },
            form_pin: false,
            pin: '',
        }
    },
    mounted() {
        if (_.intersection(this.$store.state.user.userdata.groups, this.otherGroups).length === 0) {
            if (this.wantsTeamLeader === true && this.$route.query.totem !== 'true' && this.$store.getters.caporeparto === undefined) {
                this.$store.commit('requestpin', true)
            }
        }
    },
    watch: {
        form_pin: function (newVal) {
            if ((newVal === true) && (this.pin !== '')) {
                let app = this
                this.$work.post('checkpin/', {'pin': this.pin}).then(response => {
                    if (response.data.res === 'ok') {
                        app.$toast.success(`${response.data.msg} - ${response.data.nome}`, {icon: 'mdi-thumb-up'})
                        app.$store.commit('caporeparto', {
                            'nome': response.data.nome,
                            'matricola': response.data.matricola
                        })
                        app.$store.commit('requestpin', false)
                    } else {
                        app.$toast.error(response.data.msg, {icon: 'mdi-alert', timeout: 5000});
                    }
                })
            }
        }
    },
    beforeDestroy() {
        this.$store.commit('requestpin', false)
        this.$store.commit('caporeparto', undefined)
        this.pin = ''
    }
}