import Vue from 'vue'
import Router from 'vue-router'
import dashboard from "./components/dashboard/_routes"
import laser from "./components/laser/_routes"
import articoli from "./components/articoli/_routes"
import ordini from "./components/ordini/_routes"
import staff from "./components/staff/_routes"
import avanzamenti from "./components/avanzamenti/_routes"
import tempi from "./components/tempi/_routes"
import milkrun from "./components/milkrun/_routes"
import magazzino from "./components/magazzino/_routes"
import qualita from "./components/qualita/_routes"
import acquisti from "./components/acquisti/_routes"
import fornitore from "./components/fornitore/_routes"
import spooler from "./components/spooler/_routes"
import attrezzature from "./components/attrezzature/_routes"
import store from "./store"

const DashHome = () => import('./components/DashHome')
const NotFound = () => import("./components/NotFound")
const Login = () => import("./components/Login")
const Search = () => import("./components/Search")

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isLoggedIn) {
        next()
    }
    if (from.path !== '/') {
        next('/')
    } else {
        next()
    }
}

const ifAuthenticated = async (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
    } else {
        next('/login')
    }
}


Vue.use(Router);

const routes = [
    ...dashboard,
    ...avanzamenti,
    ...laser,
    ...articoli,
    ...ordini,
    ...staff,
    ...tempi,
    ...milkrun,
    ...magazzino,
    ...qualita,
    ...acquisti,
    ...fornitore,
    ...spooler,
    ...attrezzature,
    {
        path: '/',
        name: 'Home',
        component: DashHome,
        beforeEnter: ifAuthenticated,
        meta: {title: 'Home'},

    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: ifNotAuthenticated,
        meta: {title: 'Login'}
    },
    {
        path: '/search/:query?',
        name: 'Search',
        component: Search,
        meta: {title: 'Search'}
    },
    {path: '*', component: NotFound}
];
const router = new Router({routes, mode: 'history'});

export default router;
