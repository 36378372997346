const Efficenza = () => import("./Efficenza")

const OpcUa = () => import("./OpcUa")
const Reparto = () => import("./Reparto")
const DashboardHome = () => import("./DashboardHome")
import store from "../../store"

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/dashboard/efficenza/',
        name: 'Efficenza',
        meta: {menu: 'dashboard', title: 'Efficenza'},
        component: Efficenza,
        title: 'Efficenza',
    },
    {
        path: '/dashboard/opcua/',
        name: 'OpcUa',
        meta: {menu: 'dashboard', title: 'OpcUa'},
        component: OpcUa,
        beforeEnter: ifAuthenticated,
        title: 'OpcUa',

    },

    {
        path: '/dashboard/reparto/:reparto',
        name: 'Reparto',
        meta: {menu: 'dashboard', title: 'Reparto'},
        props: true,
        component: Reparto,
        title: 'Reparto',
    },
    {
        path: '/dashboard/',
        name: 'DashboardHome',
        meta: {menu: 'dashboard', title: 'Dashboard'},
        component: DashboardHome,
        title: 'Dashboard',
    },
]