export default {
    title: 'Statistiche',
    icon: 'mdi-chart-multiple',
    groups: ['Amministrazione', 'Tempi&Metodi'],
    divider: true,
    submenu: [
        {title: 'Efficenza', route: {name: 'Efficenza'}},
        {title: 'Assemblaggio', route: {name: 'Reparto', params: {reparto: 'a'}}},
        {title: 'Punzonatura', route: {name: 'Reparto', params: {reparto: 'c'}}},
        {title: 'Asportazione', route: {name: 'Reparto', params: {reparto: 'f'}}},
        {title: 'Laser', route: {name: 'Reparto', params: {reparto: 'l'}}},
        {title: 'Magazzino', route: {name: 'Reparto', params: {reparto: 'm'}}},
        {title: 'Attrezzatura e Riparazioni', route: {name: 'Reparto', params: {reparto: 'r'}}},
        {title: 'Piegatura', route: {name: 'Reparto', params: {reparto: 'p'}}},
        {title: 'Saldatura', route: {name: 'Reparto', params: {reparto: 's'}}},
        {title: 'Tubo Tornitura', route: {name: 'Reparto', params: {reparto: 't'}}},
        {title: 'Deformazione', route: {name: 'Reparto', params: {reparto: 'd'}}},
        {title: 'Opc-Ua', 'route': {name: 'OpcUa'}}
    ]
}