<template>
    <v-row :dense="vertical || scrollable || dense" :class="[vertical ? 'flex-column' : scrollable ? 'scrollme': dense ? '': 'ma-1']">
        <v-col v-for="f,f_id in files" v-bind:key="f.id" class="shrink">
            <template v-if="f.ext === 'jpg' || f.ext === 'jpeg' || f.ext === 'png'">
                <v-dialog v-model="dialog[f_id]">
                    <template v-slot:activator="{ on, attrs }">
                        <v-img :src="thumburl + f.code + '/'"
                               style="cursor: zoom-in"
                               :width="w" :height="h"
                               v-bind="attrs"
                               class="thumb"
                               v-on="on">
                            <span :class="[dense?'imglabelsmall':'imglabel']" v-if="f.imballaggio">imballaggio</span>
                        </v-img>
                    </template>
                    <v-card>
                        <v-card-title style="text-align: center">{{ f.name }}</v-card-title>
                        <v-card-text>
                            <v-img style="cursor: zoom-out;"
                                   :src="fullurl + f.code + '/'" contain height="80vh" @click="$set(dialog,f_id,false)">
                            </v-img>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
            <template v-if="f.ext === 'pdf'">
                <v-img
                       :src="thumburl + f.code + '/'" style="cursor: zoom-in"
                       :width="w" :height="h"
                       @click="showPdf(f.name,f.code)" class="thumb">
                    <span :class="[dense?'imglabelsmall':'imglabel']" v-if="f.imballaggio">imballaggio</span>
                    <span :class="[dense?'imglabelsmall':'imglabel']" v-if="f.label">{{ f.label }}</span>
                </v-img>
            </template>
            <template v-if="f.dxf !== undefined && f.dxf !== null  && !onlypics">
                <v-img :src="`${$workUrl}/dxficon/` + f.png"
                       :width="w" :height="h"
                       @click.stop="getDxf(dxfurl+ f.dxf)" class="thumb downloadcursor">
                    <span :class="[dense?'imglabelsmall':'imglabel']" v-if="f.label">{{ f.label }}</span>
                </v-img>
            </template>
            <template v-if="f.download !== undefined && f.download !== null  && !onlypics">
                <v-img :src="`${$workUrl}/img/${w}x${h}xh/${f.articolo}.jpg`"
                       :width="w" :height="h"
                       @click.stop="getDxf(dxfurl+ f.download)"
                       class="thumb downloadcursor">
                    <span :class="[dense?'imglabelsmall':'imglabel']" v-if="f.label">{{ f.label }}</span>
                </v-img>
            </template>
            <template v-if="f.svg !== undefined && f.svg !== null">
                <v-dialog v-model="dialog[f_id]">
                    <template v-slot:activator="{ on, attrs }">
                        <v-img :src="`${$workUrl}` + f.svg"
                               style="cursor: zoom-in"
                               :width="w" :height="h"
                               v-bind="attrs"
                               class="thumb"
                               contain
                               v-on="on"
                        >
                            <span :class="[dense?'imglabelsmall':'imglabel']">{{ f.width }} x {{ f.height }}</span>
                        </v-img>
                    </template>
                    <v-card>
                        <v-card-title style="text-align: center">
                            <v-row>
                                <v-col>{{ f.programma }}</v-col>
                                <v-col>{{ f.width }} x {{ f.height }}</v-col>
                            </v-row>
                        
                        </v-card-title>
                        <v-card-text>
                            <v-img style="cursor: zoom-out;"
                                   :src="`${$workUrl}` + f.svg" contain height="80vh"
                                   @click="$set(dialog,f_id,false)">
                            </v-img>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            
            </template>
        </v-col>
    </v-row>
</template>

<script>
    
    
    import {bus} from "@/main";
    
    export default {
        name: "AltriFiles",
        props: {
            files: {},
            w: {Number, default: 80},
            h: {Number, default: 80},
            vertical: {Boolean, default: false},
            scrollable: {Boolean, default: false},
            onlypics: {
                type: Boolean, default: false
            },
            dense: {
                type: Boolean, default: false
            }
        },
        data: () => ({
            dialog: []
        }),
        computed: {
            thumburl: function () {
                return this.$workUrl + '/commesse/thumbnail/'
            },
            fullurl: function () {
                return this.$workUrl + '/commesse/showfile/'
            },
            dxfurl: function () {
                return this.$workUrl + '/commesse/getfile/'
            },
        },
        methods: {
            pdfExists: function (code) {
                fetch(this.$workUrl + '/commesse/getfile/' + code + '/').then(response => {
                        if (response.status === 200) {
                            return true
                        } else {
                            return false
                        }
                    }
                )
            },
            showPdf: function (title, pdf) {
                bus.$emit('show-pdf', {'disegno': title, 'pdf': pdf})
            },
            getDxf: function (file) {
                window.location = file
            },
        }
    }
</script>

<style scoped>
::v-deep(.imglabel) {
    background: rgba(240, 248, 255, 0.5);
    position: absolute;
    text-transform: uppercase;
    font-size: 0.7rem;
    padding-top: 2px;
    font-weight: bold;
    bottom: 0;
    z-index: 9999;
    color: red;
    text-align: center;
    height: 20px;
    border: red 1px solid;
    white-space: nowrap;
    left: 0;
    width: 100%;
    line-height: 1.5em;
}

::v-deep(.imglabelsmall) {
    background: rgba(240, 248, 255, 0.5);
    position: absolute;
    text-transform: uppercase;
    font-size: 0.3rem;
    padding-top: 2px;
    font-weight: bold;
    bottom: 0;
    z-index: 9999;
    color: red;
    text-align: center;
    border: red 1px solid;
    white-space: nowrap;
    left: 0;
    width: 100%;
    line-height: 1.5em;
}

::v-deep(.thumb) {
    border: #6d6f8a 1px solid;
}
</style>