export default {
    title: 'Attrezzature',
    icon: 'mdi-hammer-screwdriver',
    groups: ['Amministrazione', 'Magazzino', 'Tecnici', 'Tempi&Metodi', 'Qualita'],
    submenu: [
        {
            title: 'Gestione',
            route: {name: 'attrezzature-list'},
        },
        /*
        {
            title: 'Creazione',
            route: {name: 'attrezzature-edit'},
        },
        */
    ]
}