<template>
    <v-btn text class="pa-1" style="height: unset"
           :color="$vuetify.theme.currentTheme.link"
           :disabled="disabled"
           :x-large="xlarge"
           :small="small"
           :block="block"
           @click.shift.left.exact.stop="sendToClipBoard(codice)"
           @click.left.exact="$router.push({ name: 'Articolo', params: { codice:codice }})"
           @click.middle.exact="newTab({ name: 'Articolo', params: { codice:codice }})"
           @click.ctrl.left.exact="newTab({ name: 'Articolo', params: { codice:codice }})">
        <div>
            <template v-if="label">
                {{ label }}
            </template>
            <template v-else>
                {{ codice }}
            </template>
        </div>
    </v-btn>
</template>
<script>
    export default {
        name: 'btArticolo',
        props: {
            block: {type: Boolean, default: false},
            codice: {type: String, required: true},
            xlarge: {type: Boolean, default: false},
            small: {type: Boolean, default: false},
            label: {type: String, required: false},
            disabled: {type: Boolean, default: false},
        },
        methods: {
            newTab: function (link) {
                let routeData = this.$router.resolve(link);
                window.open(routeData.href, '_blank');
            },
            sendToClipBoard: async function (txt) {
                let app = this
                const el = document.createElement('textarea');
                el.value = txt;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                app.$toast.info(txt, {icon: 'mdi-clipboard-text-multiple'});
            },
        }
    }
</script>
