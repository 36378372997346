export default {
    title: 'Qualità',
    icon: 'mdi-quality-high',
    groups: ['Amministrazione','Qualità','Officina'],
    divider: true,
    submenu: [
                {
            title: 'NC da Aggiornare',
            route: {name: 'qualita-aggiorna-nc'},
            groups: ['Amministrazione','Qualità','Officina']
        },
        {
            title: 'Riepilogo NC',
            route: {name: 'qualita-riepilogo-nc'},
            groups: ['Amministrazione','Qualità','Officina']
        },
    ]
}