const Articolo = () => import("./Articolo")
import store from "../../store"

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/articoli/:codice',
        name: 'Articolo',
        meta: {menu: 'articolo', title: 'Articolo'},
        component: Articolo,
        beforeEnter: ifAuthenticated,

    },
]