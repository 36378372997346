import RiepilogoNc from "./RiepilogoNc";
import NcDaAggiornare from "./NcDaAggiornare";

import store from "../../store"

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/qualita/riepologonc/',
        name: 'qualita-riepilogo-nc',
        meta: {menu: 'qualita', title: 'Riepilogo NC'},
        component: RiepilogoNc,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/qualita/aggiornanc/',
        name: 'qualita-aggiorna-nc',
        meta: {menu: 'qualita', title: 'Aggiorna NC'},
        component: NcDaAggiornare,
        beforeEnter: ifAuthenticated,
    },
]