import store from "../../store"


const AttrezzaturaList = () => import("./AttrezzaturaList.vue")
const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/attrezzature/list/',
        name: 'attrezzature-list',
        meta: {menu: 'attrezzature', title: 'Lista Attrezzature'},
        component: AttrezzaturaList,
        beforeEnter: ifAuthenticated,
    },
]