<template>
    <altri-files v-if="nc" :files="nc.foto" :h="40" :w="40"/>
</template>
<script>
    
    import AltriFiles from "../../include/AltriFiles.vue";
    
    
    export default {
        name: 'FilesNC',
        components: {AltriFiles},
        props: {
            numero: {
                type: String,
                required: true
            },
            riga: {
                type: Number,
                required: true
            }
        }, data: () => ({
            nc: undefined,
            loading: false
        }),
        mounted() {
            this.loading = true;
            this.$work.get(`/arts/nc/${this.numero}/${this.riga}/`).then(response => {
                this.nc = response.data;
            }).finally(() => {
                this.loading = false;
            });
        }
    }

</script>
