export default {
    title: 'Avanzamento',
    icon: 'mdi-account-clock',
    groups: ['Laser', 'Admin', 'Amministrazione', 'Tecnici', 'Officina', 'Magazzino', 'Schedulazione', 'Milkrun', 'Tempi&Metodi', 'Qualità'],
    submenu: [
        {
            title: 'Seleziona Operatore',
            route: {name: 'av-operatori', params: {presente: 'p'}},
            //badge: {url: '/staff/prezzi/'},
        },
        {
            title: 'Seleziona Fase',
            route: {name: 'av-fasi'},
            //badge: {url: '/staff/prezzi/'},
        },

        {
            title: 'Schedulazioni',
            route: {name: 'av-schedulazioni'},
            //badge: {url: '/staff/prezzi/'},
        },

        {
            title: 'Riordino Lavorazioni',
            route: {name: 'av-riordinolavorazioni'},
            groups: ['Schedulazione', 'Amministrazione', 'Tempi&Metodi'],
        },
        {
            title: 'Programmi Piegatura',
            icon: 'mdi-transfer',
            route: {name: 'av-progpiega'},
            //badge: {url: '/staff/prezzi/'},
        },
    ]
}