export default {
    title: 'Ordini',
    icon: 'mdi-newspaper-variant-multiple',
    groups: ['Amministrazione', 'Schedulazione', 'Magazzino'],
    divider: true,
    submenu: [
        {
            title: 'Ordini Vendita',
            route: {name: 'ordini-clienti'},
            groups: ['Amministrazione', 'Schedulazione', 'Magazzino']
        },
        {
            title: 'Proposte',
            route: {name: 'ordini-proposte'},
            badge: {url: '/ordini/proposte/?conteggio=true', tooltip: 'Conteggio Offerte'},
            groups: ['Amministrazione', 'Schedulazione']
        },
        {
            title: 'Offerte',
            route: {name: 'ordini-offerte'},
            badge: {url: '/ordini/offerte/?conteggio=true', tooltip: 'Conteggio Offerte'},
            groups: ['Amministrazione', 'Schedulazione']
        },
    ]
}